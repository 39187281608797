import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import styles from "./styles.module.css";
const PageFiveScroll3 = ({ recommendedProArray, common }) => {
  const [selectedHover, setSelectedHover] = useState(null)
  return (
    <div style={{ position: 'relative', marginBottom: '20px' }}>
      <div className={styles.yourAnalysisHeading}>
        <h1 className={styles.yourAnalysisTitle}>Recommendations</h1>
        <div className={styles.yourAnalysisArrows}>
          <img className={styles.arrow} id="yourAnalysisLeft" src="assets/images/profile-page/left-arrwo-01.svg" alt="" />
          <img className={styles.arrow} id="yourAnalysisRight" src="assets/images/profile-page/right-arrow-01.svg" alt="" />
        </div>
      </div>
      <Swiper
        slidesPerView={2.3}
        spaceBetween={20}
        loop={false}
        navigation={{
          nextEl: "#yourAnalysisRight",
          prevEl: "#yourAnalysisLeft",
        }}
        modules={[Navigation]}
        breakpoints={{
          240: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          470: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3.7,
            spaceBetween: 20,
          },
          990: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2.5,
            spaceBetween: 40,
          },
          1224: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
        }}
      >
        {/* {recommendedProArray && */}
        {recommendedProArray.map((data, index) => {
          {/* console.log(data.front_img); */ }
          let slicedTitle = data.product_brand;
          slicedTitle = slicedTitle.substring(0, 20);
          slicedTitle = slicedTitle.substring(0, slicedTitle.lastIndexOf(" "));
          return (
            <SwiperSlide className={styles.SlideComponent} onMouseEnter={() => setSelectedHover(index)} onMouseLeave={() => setSelectedHover(null)} key={"page5-3-" + index}>
              <div className={styles.card}>
                <div className={styles.percentage}>
                  <p>{(data?.score * 100).toString().slice(0, 5) + "%"}</p>
                </div>
                <img className={styles.cardImage} src={`https://s3.amazonaws.com/hdproductimage/${data.front_img}`} alt="" />
                <div
                  style={{
                    // color: "black",
                    borderRadius: "10px",
                  }}
                  className={styles.names}
                >
                  <p className={styles.cardName}>{slicedTitle}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {selectedHover !== null && <span className={styles.tooltip}>
        <p>{recommendedProArray[selectedHover]?.product_brand}</p>
        {/* {(recommendedProArray[selectedHover]?.product_ingredients)?.replace(/\|/g, ",")} */}
        {(recommendedProArray[selectedHover]?.product_ingredients)?.map((ing, index) => {
          if (common && common.includes(ing)) {
            return <>
              <span style={{ background: 'linear-gradient(119.46deg, #ff7cb2 0%, #ff4793 98.21%)', fontWeight: '600', padding: '1px 2px', borderRadius: '4px' }} key={"rec-slide" + index}>
                {ing}
              </span>,
            </>
          } else
            return ing + ", "
        })}
      </span>}
    </div>
  );
};

export default PageFiveScroll3;
