import React, { useContext, useEffect, useMemo } from "react";
import axios from "axios";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { UserContext } from "../../../context/authContext";
import styles from './styles.module.css'

const token = localStorage.getItem("access");
let setHeader = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

function ImageFilter({ data, maskedImage, originalImageLink, hairDensity, questionID }) {
  // const newVideoURL = JSON.parse(localStorage.getItem("newHairDescription"));
  // const { loggedIn } = useContext(UserContext);
  // const [response, setResponse] = useState({});
  // const [loading, setLoading] = useState(true)
  const [hairColor, setHairColor] = useState({ color: "", colorDesc: "" });
  const [toggle, setToggle] = useState(true)
  const [toggleOriginal, setToggleOriginal] = useState(true)

  // useEffect(() => {
  //   axios.get(newVideoURL["task-response-status-url"], setHeader).then((response) => {
  //     if (response.status === 200) {

  //       setResponse(response.data);
  //       console.log("Response ZB", response.data)
  //       setLoading(false)
  //       if (status !== 200) {
  //         setStatus(200)
  //       }
  //     }
  //     setStatus(response.status)
  //     console.log("RES IMGFILTER", response)
  //   });
  // }, [])

  const shouldShow = useMemo(() => {
    if (questionID && questionID.data[0]) {
      const array = [];
      const data = questionID.data;
      // eslint-disable-next-line array-callback-return
      data.map((data) => {
        for (let i = 1; i < 6; i++) {
          if (data[`hair_goal_${i}`] !== null) {
            array.push(data[`hair_goal_${i}`]);
          }
        }
      });
      return array;
    }
    return [];
  }, [questionID]);

  useEffect(() => {
    if (data && data["hair-color-list"]) {
      // console.log(data['hair-color-list'])
      // const firstHairColor = data["hair-color-list"][0].color;
      // console.log("===> ~ file: index.jsx:29 ~ useEffect ~ firstHairColor", firstHairColor);
      // setHairColor({ color: firstHairColor.substr(0, 8), colorDesc: firstHairColor });
      setHairColor(data["hair-color-list"].slice(0, 3))
    }
  }, [data]);
  const bubblesInfo = <>
    <div
      style={{
        top: "30px",
        left: "20px",
        maxWidth: "200px"
      }}
      className="profile-box"
    >
      <span className="profile-box-title">Hair Color</span>
      <span
        style={{
          padding: "7px 7px",
        }}
        className="profile-box-button">
        {/* {hairColor.color}... */}
        {hairColor[0]?.color}
        <span className="tooltip-text">
          <ol style={{ paddingLeft: '1rem' }}>
            <li>{hairColor[0]?.color}</li>
            <li>{hairColor[1]?.color}</li>
            <li>{hairColor[2]?.color}</li>
          </ol>
        </span>
      </span>
    </div>
    <div
      style={{
        top: "40px",
        right: "20px",
      }}
      className="profile-box2"
    >
      <span className="profile-box-title">Hair Style</span>
      <span className="profile-box-button" style={{ background: '#b100cd' }}>{data["hair-style"]}</span>
    </div>
    {shouldShow.includes("improve density") || shouldShow.includes("reduce shedding") ? (
      <div
        style={{
          bottom: "34%",
          left: "20px",
        }}
        className="profile-box3"
      >
        <span className="profile-box-title">Hair Density</span>
        <span className="profile-box-button" style={{ background: '#1aa7ec' }}>{hairDensity && hairDensity["hair_density"]}</span>
      </div>
    ) : (
      <></>
    )}
    {data["hair-style"] === "Wash & Go" && (
      <div
        style={{
          bottom: "20%",
          right: "20px",
        }}
        className="profile-box3"
      >
        <span className="profile-box-title">Hair Type</span>
        <span className="profile-box-button" style={{ background: '#00C9C9' }}>{data["hair-type-display"]}</span>
      </div>
    )}
    {data && data["skin-color"] !== "FACE NOT DETECTED" &&
      <div
        style={{
          bottom: "3%",
          left: data["hair-style"] === "Wash & Go" ? "80px" : "160px",
        }}
        className="profile-box3"
      >
        <span className="profile-box-title">Skin Color</span>
        <span
          style={{
            background: data["skin-color-hex-code"],
            padding: "7px 7px",
          }}
          className="profile-box-button"
        >
          L'Oréal {data["skin-color"]}
        </span>
      </div>
    }
  </>

  return (
    <div className="main-profile-img">
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={false}
        height="parent"
        navigation={{
          nextEl: "#yourProfileisRight",
          prevEl: "#yourFilterisLeft",
        }}
        modules={[Navigation]}>
        <SwiperSlide>
          {data && data["masked-image"] ? (
            <div className="main-profile-group" style={{ fontSize: '12px' }}>
              <p className={styles.filterTitle}>Masked Image</p>
              <img src={toggle ? "/assets/images/profile-page/toggle-right.svg" : "/assets/images/profile-page/toggle-left.svg"} onClick={() => setToggle(old => !old)} alt="toggle"
                style={{ position: 'absolute', width: "50px", right: '4px', top: '2px', cursor: 'pointer' }} />
              <img src={maskedImage ? data["masked-image"] : originalImageLink} loading="lazy" alt="img" />
              {/* <img src={maskedImage ? data["masked-image"] : data["input-image"]} alt="img" /> */}
              {/* OVERLAY */}
              {toggle && (
                bubblesInfo
              )}
              {/* OVERLAY END */}
            </div>
          ) : (
            <img alt="img" />
          )}
        </SwiperSlide>
        <SwiperSlide style={{ fontSize: '12px' }}>
          <p className={styles.filterTitle}>Original Image</p>
          <img src={toggleOriginal ? "/assets/images/profile-page/toggle-right.svg" : "/assets/images/profile-page/toggle-left.svg"} onClick={() => setToggleOriginal(old => !old)} alt="toggle original"
            style={{ position: 'absolute', width: "50px", top: '2px', right: '4px', cursor: 'pointer' }} />
          <img alt="img" src={data['input-image']} />
          {toggleOriginal &&
            (
              bubblesInfo
            )}
        </SwiperSlide>

        {localStorage.getItem('cameraImg') && (
          <>
            <SwiperSlide style={{ fontSize: '12px' }}>
              <p className={styles.filterTitle}>Hair Assist Images</p>
              <div className={"row " + styles.collage}>
                {['front', 'right', 'left', 'top', 'edge-right', 'edge-left'].map((imgName, imgIndex) => {
                  return (
                    <div key={"collage-" + imgIndex} className={"col-4 " + styles.collageItem}>
                      <img src={localStorage.getItem('cameraImg') + imgIndex + ".jpg"} alt="collage img" />
                      <p>{imgName.toUpperCase()}</p>
                    </div>
                  )
                })}
              </div>
            </SwiperSlide>
          </>
        )}

        {/* HAIR DENSITY RELATED */}
        {shouldShow.includes("improve density") || shouldShow.includes("reduce shedding") ? (
          <>
            <SwiperSlide>
              <p className={styles.filterTitle}>Output Image</p>
              <img alt="img" src={hairDensity && hairDensity?.output_image_url ? (hairDensity.output_image_url) : ("/")} />
            </SwiperSlide>
            <SwiperSlide>
              <p className={styles.filterTitle}>Segmented Image</p>
              <img alt="img" src={hairDensity && hairDensity.segmented_images_url ? (hairDensity.segmented_images_url) : "/"} />
            </SwiperSlide>
            <SwiperSlide>
              <p className={styles.filterTitle}>Threshold Image</p>
              <img alt="img" src={hairDensity?.threshold_image_url ?? "/"} />
            </SwiperSlide>
          </>
        ) : (
          <></>
        )}
        {/* END         */}
        <SwiperSlide>
          <p className={styles.filterTitle}>Only Hair</p>
          <img alt="img" src={data["only-hair-image"] ?? "/"} />
        </SwiperSlide>
        <SwiperSlide>
          <p className={styles.filterTitle}>Hair Color Overlayed</p>
          <img alt="img" src={data["hair-color-overlayed-image"] ?? "/"} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default ImageFilter;
