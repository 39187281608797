import axios from "axios";
import { useEffect, useState } from "react";
import { API } from "../../api/API";
import Footer from "../../components/Footer";
import PageFiveScroll1 from "../../components/page-five";
import PageFiveScroll2 from "../../components/page-five2";
import PageFiveScroll3 from "../../components/page-five3";
import jwt_decode from "jwt-decode";
import "./effect.css";
import styles from "./profile.module.css";
import ImageFilter from "../../components/Profile/ImgFilter";
import PageFiveScroll4 from "../../components/page-five4";
const Profile = () => {
  const oldVideoURL = JSON.parse(localStorage.getItem("oldHairDescription"));
  const newVideoURL = JSON.parse(localStorage.getItem("newHairDescription"));

  const questionierId = localStorage.getItem("questionierId");

  const [data, setdata] = useState({});

  const [recommendationImage, setRecommendationImage] = useState({});
  const [recommendedProducts, setRecommendedProducts] = useState();
  const [questionID, setQuestionID] = useState();
  const [hairDensity, setHairDensity] = useState();
  const [firstName, setName] = useState("");
  const [maskedImage, setMaskedImage] = useState(true);
  const [favourites, setFavourites] = useState([])
  const [commonList, setCommonList] = useState([])
  const [originalImageLink, setOriginalImageLink] = useState(localStorage.getItem("originalImageLink"));

  const token = localStorage.getItem("access");
  let setHeader = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    const token = localStorage.getItem("access");
    let hairGoals = localStorage.getItem("goals")
    console.log("goals: ", hairGoals)
    setName(jwt_decode(token).firstName);
    // console.log(jwt_decode(token));
    const favProductList = JSON.parse(localStorage.getItem("favProductList"));
    const hairDensity = JSON.parse(localStorage.getItem("hairDensity"));

    axios.get(oldVideoURL["task-response-status-url"], setHeader).then((oldRes) => {
      axios.get(newVideoURL["task-response-status-url"], setHeader).then((newRes) => {
        setdata({ ...oldRes.data, ...newRes.data });
        // console.log("data=========>>>>>>>", { ...oldRes.data, ...newRes.data });
      });
    });

    axios.get(`${API}/question-and-answer?id=${questionierId}`, setHeader).then((response) => {
      setQuestionID(response.data)
    });

    axios.get(`${API}/get-recommendations?top-k=30&favourites=${favProductList}&goals=${hairGoals}`, setHeader).then((response) => {
      // let temp = response?.data["recommended-products"]
      let temp = response?.data["recommended-products"]?.map(ing => {
        let ingNames = ing?.product_ingredients?.split('|')?.map((ingredient) => {
          return ingredient?.trim()
        })
        return { ...ing, product_ingredients: ingNames }
      })
      setRecommendedProducts(temp)
    });

    axios.get(`${hairDensity["task-response-status-url"]}`, setHeader).then((response) => setHairDensity(response.data));
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 1) {
      let hairType = data["hair-type"];
      // let hairType = "karthik";
      const token = localStorage.getItem("access");
      axios
        .get(`${API}/detail-page-image?q=${hairType}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => setRecommendationImage(response.data));
      localStorage.setItem("hairType", hairType);
      localStorage.setItem("originalImageLink", data["input-image"]);
    }
    setOriginalImageLink(localStorage.getItem("originalImageLink"));
  }, [data]);
  useEffect(() => {
    const favouritesData = JSON.parse(localStorage.getItem('favouritesInDetail'))
    let temp = favouritesData?.map(item => {
      return {
        id: item[0],
        name: item[1],
        img: item[3],
        ingredients: item[4]?.split('|')?.map((ingredient) => {
          return ingredient?.trim()
        })
      }
    })
    setFavourites(temp)
  }, [])

  useEffect(() => {
    let favouritesList = favourites?.map(ing => {
      return ing?.ingredients
    })
    let recommendedList = recommendedProducts?.map(ing => {
      return ing?.product_ingredients
    })
    function arrayIntersection(...arrays) {
      return arrays?.reduce((previousArray, currentArray) => {
        return previousArray?.filter(element => currentArray.includes(element));
      });
    }
    function arrayUnion(arrays) {
      const flattenedArray = arrays?.reduce((previousArray, currentArray) => {
        return previousArray?.concat(currentArray);
      }, []);

      return Array.from(new Set(flattenedArray));
    }
    let favListUnion = arrayUnion(favouritesList)
    let recListUnion = arrayUnion(recommendedList)

    // console.log("common Array", arrayIntersection(favListUnion, recListUnion))
    // console.log("fav", favListUnion)
    // console.log("rec", recListUnion)
    setCommonList(arrayIntersection(favListUnion, recListUnion))
  }, [favourites, recommendedProducts])

  // console.log(data && data["masked-image"]);
  return (
    <div className="page5">
      {/*=================Start Main Body Section==============*/}
      <section id="main-body-section" style={{ paddingBottom: 8 }}>
        <div className="container">
          <div className="row">
            {/*Logo Area*/}
            <div className="logo-area col-lg-4 col-12 custom-padding customPaddingTwo" style={{ background: "none" }}>
              {/*Responsive Logo*/}
              <div className="responsive-logo-new">
                <img src="assets/images/main-logo.svg" alt="logo" />
                <div className="responsiveBodyEffect responsiveBodyEffectProfile">
                  <img src="assets/images/all-effect/body-left-effect.png" alt="img" />
                </div>
                <div className="bodyBlueEffect" />
                <div className="profile-img-details">
                  <h1>
                    Hello, <span style={{ color: "#00C9C9" }}>{firstName}!</span>
                  </h1>
                  <p>Layla AI put this together just for you</p>
                  <div className="thank-you-notice">
                    <div className="icon-here">
                      <img src="assets/images/profile-page/t-logo.svg" alt="logo" />
                    </div>
                    {/* <div className="thank-you-text">
                      <p>
                        Thank you cosmetologist{" "}
                        <a
                          href="https://hersbychantell.com/pages/her-story"
                          style={{ color: "#00C9C9" }}>
                          Chantell
                        </a>
                        for your expert lessons.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*/// End Responsive Logo*/}
              {/*Left Side Profile Area*/}
              <div className="user-profie-details-area customPaddingTwo">
                <ImageFilter data={data} questionID={questionID} hairDensity={hairDensity} maskedImage={maskedImage} originalImageLink={originalImageLink} />
                <div className={styles.toggleWrapperBox}>
                  <span>Image Filters</span>
                  <div className={styles.yourAnalysisArrows}>
                    <img className={styles.arrow} id="yourFilterisLeft" src="assets/images/profile-page/left-arrwo-01.svg" alt="" />
                    <img className={styles.arrow} id="yourProfileisRight" src="assets/images/profile-page/right-arrow-01.svg" alt="" />
                  </div>
                </div>
                {/* <div className={styles.yourAnalysisArrows}>
                  <img className={styles.arrow} id="yourAnalysisLeft" src="assets/images/profile-page/left-arrwo-01.svg" alt="" />
                  <img className={styles.arrow} id="yourAnalysisRight" src="assets/images/profile-page/right-arrow-01.svg" alt="" />
                </div> */}

                <div className="profile-img-details dis-none">
                  <h1>
                    Hello, <span style={{ color: "#00C9C9" }}>{firstName}!</span>
                  </h1>
                  <p>Layla AI put this together just for you</p>
                  {/* <div className="thank-you-notice">
                    <div className="icon-here">
                      <img
                        src="assets/images/profile-page/t-logo.svg"
                        alt="logo"
                      />
                    </div>
                    <div className="thank-you-text">
                      <p>
                        Thank you cosmetologist{" "}
                        <a
                          href="https://hersbychantell.com/pages/her-story"
                          style={{ color: "#00C9C9" }}>
                          Chantell
                        </a>{" "}
                        for your expert lessons.
                      </p>
                    </div>
                  </div> */}
                </div>
                {/*Analysis Area*/}
                <div className="analysis-area">
                  <PageFiveScroll4 common={commonList} recommendedProArray={favourites} />


                  {/*Analysis item*/}
                </div>
                {/*/// End Analysis Area*/}
              </div>
              {/*/// End Left Side Profile Area*/}
            </div>
            {/*/// End Logo Area*/}
            {/*Right Side Area*/}
            <div className="right-side-slider-area col-lg-8 col-12 customPaddingTwo">
              {/*Main Logo*/}
              <div className="logoArea">
                <img src="assets/images/main-logo.svg" alt="logo" />
              </div>
              {/*/// End Main Logo*/}
              {/*Goal & Preferences Area*/}
              <div className="goal-perferences-tip-area row">
                {/*Goal Preferences Slider*/}
                <div className="col-lg-7" style={{ flexBasis: '65%' }}>
                  {recommendedProducts && <PageFiveScroll3 common={commonList} recommendedProArray={recommendedProducts} />}
                  <div className="goal-slider-area col-lg-12">
                    <PageFiveScroll1 goalsArray={questionID} />
                  </div>
                </div>
                {/*/// End Goal Preferences Slider*/}
                {/*Tip Area*/}
                <div className="tip-area col-lg-3">
                  <h3>Pro Tip</h3>
                  {data && <p>{data["hair-type-description"]}</p>}
                </div>
                {/*/// End Tip Area*/}
              </div>
              {/*/// End Goal & Perferences Area*/}
              {/*Start Recommendation Slider*/}
              <div className="recommendation-slider-area">
                {/*Heading*/}
                {recommendationImage && <PageFiveScroll2 imageArray={recommendationImage} />}
                {/* {recommendationImage &&
                  recommendationImage.data(
                  )} */}

                {/*/// End Heading*/}
                {/*Slider*/}

                {/*/// End Slider*/}
              </div>
              {/*/// End Recommendation Slider*/}
            </div>
            {/*/// End Right Side Area*/}
          </div>
        </div>
        {/*Body Effect*/}
        <div className="mainBodyEffect">
          <div className="profileBodyLeftEffect">
            <img src="assets/images/all-effect/body-left-effect.png" alt="img" />
          </div>
          <div className="bodyEffectBlueHere" />
          <div className="bodyEffectRedHere" />
        </div>
        {/*/// End Body Effect*/}
      </section>
      {/*=================End Main Body Section==============*/}
      {/*====================strart Footer Section====================*/}
      <Footer isHomePage={false} />
      {/*====================End Footer Section====================*/}
    </div>
  );
};

export default Profile;
