import styles from './popUp.module.css'
const WaitListPopUp = () => {

    return (
        <>
            <div
                className="modal fade"
                id="waitlistPopUp"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ borderRadius: '15px' }}>
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ width: '6px', height: '6px', float: "right" }}
                            />
                            <div id="mc_embed_signup">
                                <form
                                    action="https://myhairdays.us14.list-manage.com/subscribe/post?u=5dabe3c0bc740e7bccf476758&id=ffaa191b10&f_id=007b90e0f0"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    className="validate"
                                    target="_self"
                                >
                                    <div id="mc_embed_signup_scroll">
                                        <h2>Join The Future Of Personalized Hair Health Recommendations!</h2>
                                        <div className={styles.mainForm}>
                                            <div className="indicates-required">
                                                <span className="asterisk">*</span> indicates required
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-FIRST_NAME">
                                                            First Name <span className="asterisk">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            name="FIRST_NAME"
                                                            className="required"
                                                            id="mce-FIRST_NAME"
                                                            required=""
                                                        />
                                                        <span id="mce-FIRST_NAME-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-LAST_NAME">
                                                            Last Name <span className="asterisk">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            name="LAST_NAME"
                                                            className="required"
                                                            id="mce-LAST_NAME"
                                                            required=""
                                                        />
                                                        <span id="mce-LAST_NAME-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-COMPANY">
                                                            Company Name <span className="asterisk">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            name="COMPANY"
                                                            className="required"
                                                            id="mce-COMPANY"
                                                            required=""
                                                        />
                                                        <span id="mce-COMPANY-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-EMAIL">
                                                            Email Address <span className="asterisk">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            defaultValue=""
                                                            name="EMAIL"
                                                            className="required email"
                                                            id="mce-EMAIL"
                                                            required=""
                                                        />
                                                        <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mc-field-group size1of2">
                                                <label htmlFor="mce-PHONE">Phone </label>
                                                <input
                                                    type="number"
                                                    name="PHONE"
                                                    className=""
                                                    defaultValue=""
                                                    id="mce-PHONE"
                                                />
                                                <span id="mce-PHONE-HELPERTEXT" className="helper_text" />
                                            </div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-WEBSITE">
                                                    Website <span className="asterisk">*</span>
                                                </label>
                                                <span className="mb-1" style={{ fontSize: '10.8px' }}>Please include <b>https://</b> or <b>http://</b> before your url. Example, https://www.hairdays.com</span>
                                                <input
                                                    type="url"
                                                    defaultValue=""
                                                    name="WEBSITE"
                                                    className="required url"
                                                    id="mce-WEBSITE"
                                                    required=""
                                                />
                                                <span id="mce-WEBSITE-HELPERTEXT" className="helper_text" />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-ROLE">
                                                            Role <span className="asterisk">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            name="ROLE"
                                                            className="required"
                                                            id="mce-ROLE"
                                                            required=""
                                                        />
                                                        <span id="mce-ROLE-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-COMP_TYPE">
                                                            Company Type <span className="asterisk">*</span>
                                                        </label>
                                                        <select
                                                            name="COMP_TYPE"
                                                            className="required"
                                                            id="mce-COMP_TYPE"
                                                            required=""
                                                        >
                                                            <option value="" />
                                                            <option value="Brand">Brand</option>
                                                            <option value="Retailer">Retailer</option>
                                                            <option value="Salon">Salon</option>
                                                            <option value="Telehealth">Telehealth</option>
                                                            <option value="Professional">Professional</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                        <span id="mce-COMP_TYPE-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-COMP_SIZE">
                                                            Company Size <span className="asterisk">*</span>
                                                        </label>
                                                        <select
                                                            name="COMP_SIZE"
                                                            className="required"
                                                            id="mce-COMP_SIZE"
                                                            required=""
                                                        >
                                                            <option value="" />
                                                            <option value="Startup">Startup</option>
                                                            <option value="Indie">Indie</option>
                                                            <option value="SMB">SMB</option>
                                                            <option value="Enterprise">Enterprise</option>
                                                        </select>
                                                        <span id="mce-COMP_SIZE-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mc-field-group">
                                                        <label htmlFor="mce-USE_CASE">Use Case </label>
                                                        <input
                                                            type="text"
                                                            defaultValue=""
                                                            name="USE_CASE"
                                                            className=""
                                                            id="mce-USE_CASE"
                                                        />
                                                        <span id="mce-USE_CASE-HELPERTEXT" className="helper_text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-HEAR_ABOUT">How Did You Hear About Us? </label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    name="HEAR_ABOUT"
                                                    className=""
                                                    id="mce-HEAR_ABOUT"
                                                />
                                                <span id="mce-HEAR_ABOUT-HELPERTEXT" className="helper_text" />
                                            </div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-COMMENTS">Additional Comments </label>
                                                <input
                                                    type="text"
                                                    defaultValue=""
                                                    name="COMMENTS"
                                                    className=""
                                                    id="mce-COMMENTS"
                                                />
                                                <span id="mce-COMMENTS-HELPERTEXT" className="helper_text" />
                                            </div>
                                            {/* <div className="mc-field-group input-group">
                                            <strong>Email Format </strong>
                                            <ul>
                                                <li>
                                                    <input
                                                        type="radio"
                                                        defaultValue="html"
                                                        name="EMAILTYPE"
                                                        id="mce-EMAILTYPE-0"
                                                    />
                                                    <label htmlFor="mce-EMAILTYPE-0">html</label>
                                                </li>
                                                <li>
                                                    <input
                                                        type="radio"
                                                        defaultValue="text"
                                                        name="EMAILTYPE"
                                                        id="mce-EMAILTYPE-1"
                                                    />
                                                    <label htmlFor="mce-EMAILTYPE-1">text</label>
                                                </li>
                                            </ul>
                                        </div> */}
                                            <div id="mce-responses" className="clear">
                                                <div
                                                    className="response"
                                                    id="mce-error-response"
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    className="response"
                                                    id="mce-success-response"
                                                    style={{ display: "none" }}
                                                />
                                            </div>{" "}
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                            <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                                                <input
                                                    type="text"
                                                    name="b_5dabe3c0bc740e7bccf476758_ffaa191b10"
                                                    tabIndex={-1}
                                                    defaultValue=""
                                                />
                                            </div>

                                        </div>
                                        <div className="clear">
                                            <input
                                                type="submit"
                                                value="Subscribe"
                                                defaultValue="Subscribe"
                                                name="subscribe"
                                                id="mc-embedded-subscribe"
                                                className="button"
                                                data-bs-dismiss="modal"
                                                style={{ background: '#00c9c9' }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default WaitListPopUp